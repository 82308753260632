export const CLASSIFY_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/classify`;
export const FACE_ALIGN_ENDPOINT = `https://foqlzc9qgh.execute-api.ap-south-1.amazonaws.com/dev/face-align`;
export const FACE_SWAP_ENDPOINT = `https://foqlzc9qgh.execute-api.ap-south-1.amazonaws.com/dev/face-swap`;
export const HUMAN_POSE_ESTIMATION_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/human-pose`;
export const GENERATORS_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/generators`;
export const AUTOENCODERS_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/autoencoders`;
export const TEXT_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/text`;
export const STYLE_TRANSFER_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/style-transfer`;
export const TRANSLATOR_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/text/translate`;
export const IMAGE_CAPTION_ENDPOINT = `https://thetensorclan-backend.herokuapp.com/image-captioning`;
export const SPEECH_TO_TEXT = `https://thetensorclan-backend.herokuapp.com/speech-to-text`;
